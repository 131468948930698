$theme-colors: (
  'info': tomato,
  'danger': teal,
  'primary': #ffc72f,
  'success': #ffc72f,
  'default': #007acc
);

$primary-color: #ffc72f;
$black: #000;
$green: #43a047;
$greenLight: #bcd809;
$red600: #e53935;
$blue: #0cc9dd;

.azul {
  filter: opacity(0.5) drop-shadow(0 0 0 $blue);
}

.rojo {
  filter: opacity(0.5) drop-shadow(0 0 0 $red600);
}
.verde {
  filter: opacity(0.5) drop-shadow(0 0 0 $greenLight);
}
.amarillo {
  filter: opacity(0.5) drop-shadow(0 0 0 $primary-color);
}
