.logoAVI {
  position: absolute;
  bottom: 2em;
  right: 5em;
}

.html {
  font-size: large;
}

.borde {
  border: 1px solid black;
}

.errorLogin {
  color: red;
  font-size: medium;
}
