.block {
  display: block;
}

/* Botones de edición del grid de planificación */
.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-edit-with-icons .dx-link {
  zoom: 120%;
}

/* Botón para elminar una obra */
.trashBtn {
  cursor: pointer;
  display: contents;
}

.dx-texteditor-container {
  background-color: #f0f0f0;
}

#map {
  height: 180px;
}

.dx-datagrid-total-footer > .dx-datagrid-content {
  padding: 0;
}

.dx-datagrid-filter-panel {
  padding: 5px;
}

.dx-datagrid-headers {
  font-size: small;
}

.dx-datagrid .dx-row > td {
  padding: 5px;
  font-size: small;
}
