.titulo {
  font-weight: bold;
  font-size: large;
}

.contenedorPrincipal {
  padding: 1em;
}

.filaParametro {
  padding: 5px 1em;
  display: flex;
  align-items: center;

  span:first-of-type {
    text-align: left;
    font-weight: bold;
    justify-content: center;
  }
}
