// Sugiero hacer siempre esto, remueve márgenes generados automáticamente por el navegador
* {
  margin: 0px;
  padding: 0px;
}

.general {
  height: 100vh; // Alto de la ventana
  width: 100vw; // Ancho de la ventana
}

.modal-header {
  background-color: $primary-color;
}

.dx-button-mode-contained.dx-button-default {
  background-color: $primary-color !important;
  color: $black !important;
}
