.map-container {
  padding: 0px 5px;
  overflow: 'hidden';
}

.grid-container {
  overflow: 'hidden';
}

.btnAccion {
  margin: 0 3px;
}

.txtBtnAccion {
  font-size: 0.8em;
}

.dx-button.dx-button-default {
  border-radius: 4px;
  border-color: #ffc72f;
  background-color: #ffc72f;
  color: #000;
}

.dx-datagrid-total-footer > .dx-datagrid-content {
  padding: 0;
}

.dx-datagrid-filter-panel {
  padding: 5px;
}

.dx-datagrid-headers {
  font-size: small;
}

.dx-datagrid .dx-row > td {
  padding: 5px;
  font-size: small;
}
