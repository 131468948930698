.link {
  color: #fff;
  text-decoration: none;
}

.dx-datagrid-total-footer > .dx-datagrid-content {
  padding: 0;
}

.dx-datagrid-filter-panel {
  padding: 5px;
}

.dx-datagrid-headers {
  font-size: small;
}

.dx-datagrid .dx-row > td {
  padding: 5px;
  font-size: small;
}
